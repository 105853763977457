// App.js

import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Navbar from './components/Navbar.js';
import Content from './components/Content.js';
import LabelBottomNavigation from './components/BottomTab.js';
import { BrowserRouter, Router, Route, Redirect, Switch, Link, histoy, withRouter } from 'react-router-dom';
import Message from './components/Message.js';
import Home from './components/Home.js';
import FarmingAdvice from './components/FarmingAdvice.js';
import Profile from './components/Profile.js';
import Wallet from './components/Wallet.js';
import Pest from './components/Pest.js';
import Farms from './components/Farms.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import RegPart1 from './components/RegPart1';
import AboutUs from './components/AboutUs';
import AddNewFarm from './components/AddNewFarm.js';
import ChatBox from './components/ChatBox.js';
import ImageView from './components/ImageView.js';
import QuestionForm from './components/QuestionForm.js';
import AddNewQuestion from './components/AddNewQuestion.js';
import axios from 'axios';
import ReactCountdownClock from 'react-countdown-clock';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Correction from './components/Correction.js';
import Appointment from './components/Appointment.js';
import FarmVisit from './components/FarmVisit.js';
import Fertilizer from './components/Fertilizer';
import WateringAdvice from './components/WateringAdvice';
import SubmitNewFarm from './components/SubmitNewFarm.js';
import Disease from './components/FarmDetails/Disease.js';
import Watering from './components/FarmDetails/Watering.js';
import CorrectionOperation from './components/FarmDetails/CorrectionOperation.js';
import FertilizerBackground from './components/FarmDetails/FertilizerBackground.js';
import AnimalFertiziler from './components/FarmDetails/AnimalFertiziler.js';
import ChemicalFertilizerOAF from './components/FarmDetails/ChemicalFertilizerOAF.js';
import CFOAFlastYear from './components/FarmDetails/CFOAFlastYear.js';
import CFOAFlastTwoYear from './components/FarmDetails/CFOAFlastTwoYear.js';
import ChemicalFertilizerDuringGrowth from './components/FarmDetails/ChemicalFertilizerDuringGrowth.js';
import SprayingSolutionFertilizer from './components/FarmDetails/SprayingSolutionFertilizer.js';
import Loading from './components/Loading.js';
import DiseaseAdvice from './components/DiseaseAdvice.js';
import FarmsPreview from './components/FarmsPreview/FarmsPreview.js';
import FarmsEdit from './components/FarmsEdit/FarmsEdit.js';
import GeneralFarmDetails from './components/FarmsEdit/GeneralFarmDetails.js';
import DiseaseEdit from './components/FarmsEdit/DiseaseEdit.js';
import CorrectionOperationEdit from './components/FarmsEdit/CorrectionOperationEdit.js';
import WateringEdit from './components/FarmsEdit/WateringEdit.js';
import Pistachioable from './components/Pistachioable/Pistachioable.js';
import RegionWise from './components/Pistachioable/RegionWise.js';
import WaterWise from './components/Pistachioable/WaterWise.js';
import SoilWise from './components/Pistachioable/SoilWise.js';
import FertilizerBackgroundEdit from './components/FarmsEdit/FertilizerBackgroundEdit.js';
import AnimalFertizilerEdit from './components/FarmsEdit/AnimalFertizilerEdit.js';
import ChemicalFertilizerOAFEdit from './components/FarmsEdit/ChemicalFertilizerOAFEdit.js';
import ChemicalFertilizerDuringGrowthEdit from './components/FarmsEdit/ChemicalFertilizerDuringGrowthEdit.js';
import SprayingSolutionFertilizerEdit from './components/FarmsEdit/SprayingSolutionFertilizerEdit.js';
import CFOAFlastYearEdit from './components/FarmsEdit/CFOAFlastYearEdit.js';
import CFOAFlastTwoYearEdit from './components/FarmsEdit/CFOAFlastTwoYearEdit.js';
import { askForPermissioToReceiveNotifications } from "./init-fcm";
import { BrowserView, MobileView, isIOS, isMobile, isAndroid } from "react-device-detect";
import PestFirstLevel from './components/PestFirstLevel.js';
import ChatDemo from './components/ChatDemo.js';
import { AwesomeButtonProgress } from 'react-awesome-button';
import ReactCodeInput from 'react-code-input';
import Slide from 'react-reveal/Slide';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Shake from 'react-reveal/Shake';
import Blog from './components/Blog.js';
import TermsAndConditions from './components/TermsAndConditions.js';


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'Vazir',
      'sans-serif'
    ].join(','),
  }
});
var regCredentials = null;

class App extends Component {

  constructor(props) {

    super(props);
    this.changePhuneNumber = this.changePhuneNumber.bind(this);
    this.changeVerificationCode = this.changeVerificationCode.bind(this);
    this.phoneNumberCorretion = this.phoneNumberCorretion.bind(this);
    this.onCodeConfirmClick = this.onCodeConfirmClick.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.state = { loading: false }

    if ("userTOKEN" in localStorage) {
      if (localStorage.getItem('userTOKEN').length != 0 && JSON.parse(localStorage.getItem('user_credentials')).name.length != 0) {

        this.state = { isLoggedIn: true };

      } else {
        this.state = {
          isLoggedIn: false,
          showPhoneNumber: 'block',
          showCodeConfirm: 'none',
          show_register_credentials: 'none',
          countDownPause: true,
          smsCode: '',
          showConfirmButton: false,
          apiToken: '',
          id: '',
          loading: false,
          update: false,
          code_confirm_time_out: 'none',
          code_confirm_in_time: 'block',
          completions: 0,
        }
      }
    }
    else {
      this.state = {
        isLoggedIn: false,
        showPhoneNumber: 'block',
        showCodeConfirm: 'none',
        show_register_credentials: 'none',
        countDownPause: true,
        smsCode: '',
        showConfirmButton: false,
        apiToken: '',
        id: '',
        loading: false,
        update: false,
        completions: 0,

      }
    }
  }

  componentDidMount() {

    if (localStorage.getItem('fcm_token') == null) {
      askForPermissioToReceiveNotifications();
    }


    this.setState({ update: false, loading: true })



    const axios = require('axios');
    axios.get('https://api.pestekaran.com/api/amir',
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Accept': 'application/json'
        }
      })
      .then((response) => {
        var forceUpdate = response.data.update;
        if ('1.0.1' != forceUpdate) { //Update version Goes heeeeeeeeeeeeeeeeeeeeeeeeeeeeere
          this.setState({ update: true }, () => {
            this.setState({ loading: false })
          })
        }
        else {
          this.setState({ loading: false })
        }
      })
      .catch(function (response) {
      })
      .finally(function () {
        // always executed
      });
  }


  phoneNumberCorretion = () => {

    this.setState(
      {
        show_register_credentials: 'none',
        showCodeConfirm: 'none',
        showPhoneNumber: 'block',
        code_confirm_in_time: 'block',
        code_confirm_time_out: 'none',
      })

  }

  changeVerificationCode(event) {

    if ((event.target.value).length == 4) {
      const axios = require('axios');
      axios.post('https://api.pestekaran.com/api/sms/auth',
        {

          phone_number: localStorage.getItem('phone_number'),
          sms: event.target.value
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then((response) => {
          localStorage.setItem('user_credentials', JSON.stringify(response.data.data));
          if (response.data.error == 0) {

            this.setState({ showConfirmButton: true, apiToken: response.data.data.api_token, id: response.data.data.id, code_authenticity: true });
            localStorage.setItem('userID', response.data.data.id);
            localStorage.setItem('userTOKEN', response.data.data.api_token);
          }
          else { console.log("") };
          regCredentials = <div><RegPart1 action={this.handleLoginClick} /></div>;

        })
        .catch((error) => {
          this.setState({ showConfirmButton: true, code_authenticity: false });
        })
        .finally(function () {
          // always executed
        });


    }
  }




  changePhuneNumber(event) {
    this.setState({ phoneNumber: event.target.value });
  }
  sendMessage = () => {
    this.setState({
      completions: this.state.completions + 1,
      isLoggedIn: false,
      showPhoneNumber: 'none',
      showCodeConfirm: 'block',
      code_confirm_time_out: 'none',
      code_confirm_in_time: 'block',
      countDownPause: false,
      smsCode: '',
      showConfirmButton: false,
      apiToken: '',
      id: '',

    }, () => {
      localStorage.setItem('phone_number', this.state.phoneNumber);
      const axios = require('axios');
      axios.post('https://api.pestekaran.com/api/sms/register',
        {

          phone_number: this.state.phoneNumber
          ,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          }
        })
        .then(function (response) {


        })
        .catch(function (error) {

        })
        .finally(function () {
          // always executed
        });
    });


  }

  onCodeConfirmClick = () => {
    this.setState(
      {
        show_register_credentials: 'block',
        showCodeConfirm: 'none',
        showPhoneNumber: 'none',
      })
  }

  render() {



    if (isMobile) {
      if (this.state.update) {
        return (
          <div style={{ display: 'flex', height: '100vh', width: '100%', flexDirection: "column", alignItems: 'center', justifyContent: 'space-between' }}>

            <div>
              <Slide down>
                <img src={require('./components/styles/aboutus-logo.jpg')} style={{
                  boxShadow: "5px 5px 20px 7px rgba(0,0,0,0.15), -5px -5px 30px 1px rgba(0,0,0,0.08)",
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  padding: 8,
                  width: '40vh',
                  height: '35vh',
                  marginTop: '10vh',
                  borderRadius: 40
                }} />
              </Slide>
            </div>
            <div style={{ psdding: 8, textAlign: 'center', fontSize: 14 }}>

              لطفا نسخه فعلی اپلیکیشن پسته کاران را حذف کنید و از طریق مرورگر خود نسخه جدید را نصب کنید تا از قابلیت های بروزرسانی شده استفاده کنید. با تشکر :)

</div>



            <div style={{
              marginBottom: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '80%',
              height: '30%'
            }}>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 8, textAlign: 'center', width: '100%', height: '100%', marginTop: 16 }}>
                <div style={{
                  textAlign: 'center',
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  width: '70%',
                  height: '70%',
                  backgroundImage: 'url(' + require('./components/styles/android.gif') + ')'
                }}></div>

              </div>

            </div>

            <div className="frame">
              <button className="regSubmitButton" onClick={() => { window.open('https://app.pestekaran.com/', '_blank') }}>به روز رسانی</button>
            </div>
          </div>

        );
      }
      else {
        if (this.state.loading) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Loading />
            </div>
          );
        }
        else {
          const isLogged_In = this.state.isLoggedIn;

          if (this.state.showConfirmButton) {
            if (this.state.code_authenticity) {
              var myButton = <button style={{ marginBottom: 16 }} className="regCodeConfirmButton" onClick={this.onCodeConfirmClick}>تایید کد </button>
            }
            else if (!this.state.code_authenticity) {
              var myButton = <div style={{ color: 'red', textAlign: 'center', marginBottom: 16 }}>کد وارد شده صحیح نمی باشد.</div>
            }


          }

          if (isLogged_In) {
            return (
              <ThemeProvider theme={theme}>
                <React.Fragment claasName=''>
                  <CssBaseline />
                  <Container fixed style={{ padding: 0 }}>
                    <Route exact path="/Home" component={Home} />
                    <Route path="/Message" component={Message} />
                    <Route path="/Profile" component={Profile} />
                    <Route path="/Wallet" component={Wallet} />
                    <Route path="/Pest" component={Pest} />
                    <Route path="/Farms" component={Farms} />
                    <Route path="/AddNewFarm" component={AddNewFarm} />
                    <Route path='/ChatBox' component={ChatBox} />
                    <Route path='/ImageView' component={ImageView} />
                    <Route path='/QuestionForm' component={QuestionForm} />
                    <Route path='/AddNewQuestion' component={AddNewQuestion} />
                    <Route path='/Correction' component={Correction} />
                    <Route path='/SubmitNewFarm' component={SubmitNewFarm} />
                    <Route path='/Disease' component={Disease} />
                    <Route path='/Watering' component={Watering} />
                    <Route path='/Fertilizer' component={Fertilizer} />
                    <Route path='/CorrectionOperation' component={CorrectionOperation} />
                    <Route path='/FertilizerBackground' component={FertilizerBackground} />
                    <Route path='/AnimalFertiziler' component={AnimalFertiziler} />
                    <Route path='/ChemicalFertilizerOAF' component={ChemicalFertilizerOAF} />
                    <Route path='/CFOAFlastYear' component={CFOAFlastYear} />
                    <Route path='/CFOAFlastTwoYear' component={CFOAFlastTwoYear} />
                    <Route path='/ChemicalFertilizerDuringGrowth' component={ChemicalFertilizerDuringGrowth} />
                    <Route path='/SprayingSolutionFertilizer' component={SprayingSolutionFertilizer} />
                    <Route path='/WateringAdvice' component={WateringAdvice} />
                    <Route path='/FarmingAdvice' component={FarmingAdvice} />
                    <Route path='/FarmsPreview' component={FarmsPreview} />
                    <Route path='/FarmsEdit' component={FarmsEdit} />
                    <Route path='/GeneralFarmDetails' component={GeneralFarmDetails} />
                    <Route path='/DiseaseEdit' component={DiseaseEdit} />
                    <Route path='/CorrectionOperationEdit' component={CorrectionOperationEdit} />
                    <Route path='/WateringEdit' component={WateringEdit} />
                    <Route path='/FertilizerBackgroundEdit' component={FertilizerBackgroundEdit} />
                    <Route path='/AnimalFertizilerEdit' component={AnimalFertizilerEdit} />
                    <Route path='/ChemicalFertilizerOAFEdit' component={ChemicalFertilizerOAFEdit} />
                    <Route path='/ChemicalFertilizerDuringGrowthEdit' component={ChemicalFertilizerDuringGrowthEdit} />
                    <Route path='/SprayingSolutionFertilizerEdit' component={SprayingSolutionFertilizerEdit} />
                    <Route path='/CFOAFlastYearEdit' component={CFOAFlastYearEdit} />
                    <Route path='/CFOAFlastTwoYearEdit' component={CFOAFlastTwoYearEdit} />
                    <Route path='/Appointment' component={Appointment} />
                    <Route path='/FarmVisit' component={FarmVisit} />
                    <Route path='/AboutUs' component={AboutUs} />
                    <Route path='/ChatDemo' component={ChatDemo} />
                    <Route path='/PestFirstLevel' component={PestFirstLevel} />
                    <Route path='/DiseaseAdvice' component={DiseaseAdvice} />
                    <Route path='/Pistachioable' component={Pistachioable} />
                    <Route path='/RegionWise' component={RegionWise} />
                    <Route path='/WaterWise' component={WaterWise} />
                    <Route path='/SoilWise' component={SoilWise} />
                    <Route path='/Blog' component={Blog} />
                    <Route path='/TermsAndConditions' component={TermsAndConditions} />




                    <Redirect to='/Home' />
                    <LabelBottomNavigation />

                  </Container>
                </React.Fragment>
              </ThemeProvider>

            );
          }
          else {

            return (

              <div>




                <div className="phone-number-page" style={{ display: this.state.showPhoneNumber }}>
                  <div className="phone-number-elems">
                    <div className="inputContainer" style={{ marginBottom: 32 }}>
                      <h2 style={{ marginBottom: 64 }}>پسته کاران</h2>
                      <div style={{ fontSize: 14 }}>شماره همراه:</div>
                      <input type="tel" placeholder="0913XXXXXXX" className="phoneInput" style={{ direction: 'ltr', borderRadius: 0 }} onChange={this.changePhuneNumber} />
                    </div>
                    <button onClick={this.sendMessage} className="regPhoneConfirmButton">تایید شماره همراه</button>
                    <div style={{ fontSize: 14, padding: 8, marginTop: 16, textAlign: 'center' }}>توجه: اگر فایل نصبی اپ را دانلود نکرده اید و تمایل به استفاده از نسخه وب دارید (با مرورگر موبایل وارد شده اید). برای سهولت در استفاده از  برنامه پسته کاران از منو مرورگر آن را به صفحه اصلی اضافه نمایید.</div>

                  </div>
                </div>


                <div style={{ display: this.state.showCodeConfirm }}>
                  <div className="code-confirmation-page">
                    <div onClick={this.phoneNumberCorretion} className="code-confirm-page-back-button">
                      <div>اصلاح شماره</div>
                      <ArrowBackIosIcon />
                    </div>
                    <div className="phone-number-elems" style={{ display: this.state.code_confirm_in_time }}>
                      <div className="codeInputContainer">
                        <input type="tel" placeholder="کد ارسال شده" className="phoneInput" style={{ direction: 'ltr' }} onChange={this.changeVerificationCode} />
                      </div>

                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{myButton}</div>
                      <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "end", margiTop: 16 }}>
                        <div style={{ marginLeft: 5, marginTop: 10, fontSize: 14 }}>اعتبار کد:</div>
                        <ReactCountdownClock
                          key={this.state.completions}
                          seconds={90}
                          color="green"
                          showMilliseconds={false}
                          weight={5}
                          paused={this.state.countDownPause}
                          alpha={0.7}
                          size={40}
                          onComplete={() => { this.setState({ code_confirm_time_out: 'block', code_confirm_in_time: 'none' }) }} />
                      </div>
                    </div>

                    <div style={{ display: this.state.code_confirm_time_out }}>
                      <div className="phone-number-elems">
                        <div className="codeInputContainer">
                          کد ارسالی منقضی شد :(
                </div>

                        <div style={{ display: 'flex', flexDirection: "row", width: "100%", justifyContent: "center", alignItems: "end", margiTop: 16 }}>
                          <button style={{ marginBottom: 10 }} className="regCodeConfirmButton" onClick={this.phoneNumberCorretion}>تلاش مجدد </button>
                        </div>
                      </div>
                    </div>


                  </div>
                </div>

                <div style={{ display: this.state.show_register_credentials }}>
                  {regCredentials}
                </div>


              </div>

            );

          }
        }
      }



    }

    else if (!isMobile) {
      return (
        <meta http-equiv="Refresh" content="0; url=http://www.pestekaran.ir" />
      );
    }

  }


  //class
}

export default App;
